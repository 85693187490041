import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import {
  getBep20Contract,
  getCakeContract,
  getBunnyFactoryContract,
  getBunnySpecialContract,
  getPancakeRabbitContract,
  getProfileContract,
  getIfoV1Contract,
  getIfoV2Contract,
  getMasterchefContract,
  getPointCenterIfoContract,
  getSouschefContract,
  getClaimRefundContract,
  getTradingCompetitionContract,
  getEasterNftContract,
  getErc721Contract,
  getCakeVaultContract,
  getPredictionsContract,
  getChainlinkOracleContract,
  getSouschefV2Contract,
  getLotteryV2Contract,
  getBunnySpecialCakeVaultContract,
  getBunnySpecialPredictionContract,
  getFarmAuctionContract,
  getBunnySpecialLotteryContract,
  getReferrerContract,
  getBuyNftContract,
  getNftContract,
  getNftMarketContract,
  getNftAuctionContract,
  getMasterchefV2Contract,
  getEggShareContract,
  getEggPoolContract, getMiner5xContract, getBNOPoolContract, getUniswapExContract,
} from 'utils/contractHelpers'
import { getMulticallAddress } from 'utils/addressHelpers'

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { ChainId, WETH } from '@pancakeswap/sdk'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json'
import ENS_ABI from '../config/abi/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import WETH_ABI from '../config/abi/weth.json'
import multiCallAbi from '../config/abi/Multicall.json'

import testmulticall from '../config/abi/testmulticall.json'
import { getContract } from '../utils'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useIfoV1Contract = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getIfoV1Contract(address, library.getSigner()), [address, library])
}

export const useIfoV2Contract = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getIfoV2Contract(address, library.getSigner()), [address, library])
}

export const useERC20 = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBep20Contract(address, library.getSigner()), [address, library])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getErc721Contract(address, library.getSigner()), [address, library])
}

export const useCake = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCakeContract(library.getSigner()), [library])
}

export const useEggShare = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getEggShareContract(library.getSigner()), [library])
}

export const useMiner5x = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getMiner5xContract(library.getSigner()), [library])
}

export const useEggPool = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getEggPoolContract(library.getSigner()), [library])
}

export const useBNOPool = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBNOPoolContract(library.getSigner()), [library])
}

export const useBunnyFactory = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBunnyFactoryContract(library.getSigner()), [library])
}

export const usePancakeRabbits = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getPancakeRabbitContract(library.getSigner()), [library])
}

export const useProfile = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getProfileContract(library.getSigner()), [library])
}

export const useLotteryV2Contract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getLotteryV2Contract(library.getSigner()), [library])
}

export const useMasterchef = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getMasterchefContract(library.getSigner()), [library])
}

export const useMasterchefV2 = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getMasterchefV2Contract(library.getSigner()), [library])
}

export const useSousChef = (id) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getSouschefContract(id, library.getSigner()), [id, library])
}

export const useSousChefV2 = (id) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getSouschefV2Contract(id, library.getSigner()), [id, library])
}

export const usePointCenterIfoContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getPointCenterIfoContract(library.getSigner()), [library])
}

export const useBunnySpecialContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBunnySpecialContract(library.getSigner()), [library])
}

export const useClaimRefundContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getClaimRefundContract(library.getSigner()), [library])
}

export const useReferrerContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getReferrerContract(library.getSigner()), [library])
}

export const useTradingCompetitionContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getTradingCompetitionContract(library.getSigner()), [library])
}

export const useEasterNftContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getEasterNftContract(library.getSigner()), [library])
}

export const useCakeVaultContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getCakeVaultContract(library.getSigner()), [library])
}

export const usePredictionsContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getPredictionsContract(library.getSigner()), [library])
}

export const useChainlinkOracleContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getChainlinkOracleContract(library.getSigner()), [library])
}

export const useSpecialBunnyCakeVaultContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBunnySpecialCakeVaultContract(library.getSigner()), [library])
}

export const useSpecialBunnyPredictionContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBunnySpecialPredictionContract(library.getSigner()), [library])
}

export const useBunnySpecialLotteryContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBunnySpecialLotteryContract(library.getSigner()), [library])
}

export const useFarmAuctionContract = () => {
  const { account, library } = useActiveWeb3React()
  // This hook is slightly different from others
  // Calls were failing if unconnected user goes to farm auction page
  // Using library instead of library.getSigner() fixes the problem for unconnected users
  // However, this fix is not ideal, it currently has following behavior:
  // - If you visit Farm Auction page coming from some other page there are no errors in console (unconnected or connected)
  // - If you go directly to Farm Auction page
  //   - as unconnected user you don't see any console errors
  //   - as connected user you see `unknown account #0 (operation="getAddress", code=UNSUPPORTED_OPERATION, ...` errors
  //     the functionality of the page is not affected, data is loading fine and you can interact with the contract
  //
  // Similar behavior was also noticed on Trading Competition page.
  return useMemo(() => getFarmAuctionContract(account ? library.getSigner() : library), [library, account])
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  // console.log(chainId,WETH[chainId].address)
  // 56 '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'、
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}
// 函数：
//
// isApprovedForAll(address owner, address operator)：检查是否已将操作者授权为所有者的操作者。
// old()：返回旧的ENS合约地址。
// owner(bytes32 node)：返回指定节点的所有者地址。
// recordExists(bytes32 node)：检查指定节点是否存在记录。
// resolver(bytes32 node)：返回指定节点的解析器合约地址。
// setApprovalForAll(address operator, bool approved)：设置是否批准操作者操作所有者的资产。
// setOwner(bytes32 node, address owner)：设置指定节点的所有者。
// setRecord(bytes32 node, address owner, address resolver, uint64 ttl)：设置节点的记录（所有者、解析器和生存时间）。
// setResolver(bytes32 node, address resolver)：设置指定节点的解析器合约地址。
// setSubnodeOwner(bytes32 node, bytes32 label, address owner)：设置子节点的所有者。
// setSubnodeRecord(bytes32 node, bytes32 label, address owner, address resolver, uint64 ttl)：设置子节点的记录（所有者、解析器和生存时间）。
// setTTL(bytes32 node, uint64 ttl)：设置节点的生存时间。
// 事件：
//
// ApprovalForAll(address owner, address operator, bool approved)：操作者被授权或取消授权。
// NewOwner(bytes32 node, bytes32 label, address owner)：新的所有者被设置。
// NewResolver(bytes32 node, address resolver)：新的解析器被设置。
// NewTTL(bytes32 node, uint64 ttl)：新的生存时间被设置。
// Transfer(bytes32 node, address owner)：节点的所有者被转移。
// 通过使用这些函数和监听这些事件，可以与ENS Resolver合约进行交互和查询。
export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.TESTNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}
// 这段ABI描述的是一个ENS Resolver合约的接口。根据ABI，以下是合约中的一些重要函数和事件：
//
// 函数：
//
// addr(bytes32 node)：获取指定节点的地址。
// contenthash(bytes32 node)：获取指定节点的内容哈希。
// dnsRecord(bytes32 node, bytes32 name, uint16 resource)：获取指定节点的DNS记录。
// hasDNSRecords(bytes32 node, bytes32 name)：检查指定节点是否具有指定名称的DNS记录。
// interfaceImplementer(bytes32 node, bytes4 interfaceID)：获取指定节点实现指定接口的合约地址。
// name(bytes32 node)：获取指定节点的名称。
// pubkey(bytes32 node)：获取指定节点的公钥。
// setABI(bytes32 node, uint256 contentType, bytes data)：设置指定节点的ABI信息。
// setAddr(bytes32 node, uint256 coinType, bytes a)：设置指定节点的地址。
// setAuthorisation(bytes32 node, address target, bool isAuthorised)：设置指定节点的授权。
// setContenthash(bytes32 node, bytes hash)：设置指定节点的内容哈希。
// setDNSRecords(bytes32 node, bytes data)：设置指定节点的DNS记录。
// setInterface(bytes32 node, bytes4 interfaceID, address implementer)：设置指定节点实现的接口。
// setName(bytes32 node, string name)：设置指定节点的名称。
// setPubkey(bytes32 node, bytes32 x, bytes32 y)：设置指定节点的公钥。
// setText(bytes32 node, string key, string value)：设置指定节点的文本信息。
// supportsInterface(bytes4 interfaceID)：检查合约是否支持指定接口。
// text(bytes32 node, string key)：获取指定节点的文本信息。
// 事件：
//
// ABIChanged(bytes32 node, uint256 contentType)：ABI信息发生变化的事件。
// AddrChanged(bytes32 node, address a)：地址发生变化的事件。
// AddressChanged(bytes32 node, uint256 coinType, bytes newAddress)：地址发生变化的事件。
// AuthorisationChanged(bytes32 node, address owner, address target, bool isAuthorised)：授权变化的事件。
// ContenthashChanged(bytes32 node, bytes hash)：内容哈希发生变化的事件。
// DNSRecordChanged(bytes32 node, bytes name, uint16 resource, bytes record)：DNS记录发生变化的事件。
// DNSRecordDeleted(bytes32 node, bytes name, uint16 resource)：删除DNS记录的事件。
// DNSZoneCleared(bytes32 node)：清除DNS区域的事件。
// InterfaceChanged(bytes32 node, bytes4 interfaceID, address implementer)：接口发生变化的事件。
// NameChanged(bytes32 node, string name)：名称发生变化的事件。
// PubkeyChanged(bytes32 node, bytes32 x, bytes32 y)：公钥发生变化的事件。
// TextChanged(bytes32 node, string indexedKey, string key)：文本信息发生变化的事件。
// 通过使用这些函数和监听这些事件，可以与ENS Resolver合约进行交互和查询。
export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  // let { chainId } = useActiveWeb3React()
  // if (chainId == 97){
  //   return useContract(getMulticallAddress(), testmulticall, false)
  // }
  return useContract(getMulticallAddress(), multiCallAbi, false)
}

export const useNftContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNftContract(library.getSigner()), [library])
}

export const useGetNftContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNftContract(library), [library])
}

export const useBoxContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBuyNftContract(library.getSigner()), [library])
}

export const useGetBoxContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getBuyNftContract(library), [library])
}

export const useGetNftMarketContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNftMarketContract(library), [library])
  // return useMemo(() => getNftMarketContract(), [])
}

export const usePostNftMarketContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNftMarketContract(library.getSigner()), [library])
}


export const useGetNftAuctionContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNftAuctionContract(library), [library])
}

export const usePostNftAuctionContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNftAuctionContract(library.getSigner()), [library])
}

// returns null on errors
export function useUniswapExContract(withSignerIfPossible = true) {
  const { chainId, library, account } = useActiveWeb3React()

  return useMemo(() => {
    try {
      return getUniswapExContract(chainId, library, withSignerIfPossible ? account : undefined)
    } catch (e) {
      return null
    }
  }, [chainId, library, withSignerIfPossible, account])
}
