import { Currency, currencyEquals, ETHER, WETH } from '@pancakeswap/sdk'
import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { tryParseAmount } from '../state/swap/hooks'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useCurrencyBalance } from '../state/wallet/hooks'
import { useWETHContract } from './useContract'
import { useCallWithGasPrice } from './useCallWithGasPrice'

export enum WrapType {
  NOT_APPLICABLE,
  WRAP,
  UNWRAP,
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE }
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(
  inputCurrency: Currency | undefined,
  outputCurrency: Currency | undefined,
  typedValue: string | undefined,
): { wrapType: WrapType; execute?: undefined | (() => Promise<void>); inputError?: string } {
  const { chainId, account } = useActiveWeb3React()//获取chainId和当前连接的账户
  const { callWithGasPrice } = useCallWithGasPrice()//获取调用合约内部方法的一个封装方法
  const wethContract = useWETHContract() //其实就是WBNB代币,是以太坊上的代币，以代替币安链上BNB，实现在以太坊网络上的与其他代币交易功能
  const balance = useCurrencyBalance(account ?? undefined, inputCurrency) //获取当前账户inputCurrency（可能是代币，也可能是BNB原生货币）的余额
  // we can always parse the amount typed as the input currency, since wrapping is 1:1
  //将输入金额转为wei
  const inputAmount = useMemo(() => tryParseAmount(typedValue, inputCurrency), [inputCurrency, typedValue])
  const addTransaction = useTransactionAdder()//返回一个保存交易的方法（这里指将刚发生的交易hash存入本地state状态里）

  return useMemo(() => {
    // WBNB合约不存在，chainId不存在，输入输出货币不存在，返回不适用
    if (!wethContract || !chainId || !inputCurrency || !outputCurrency) return NOT_APPLICABLE

    //判断余额是否足够
    const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount)
    //如果输入货币是BNB，且输出货币是WBNB
    if (inputCurrency === ETHER && currencyEquals(WETH[chainId], outputCurrency)) {
      //返回是wrap交易，所谓wrap其实就是BNB与其对应的代币WBNB的交易
      // inputAmount.raw.toString(16) 将输入金额转为16进制字符串
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  //发起交易callWithGasPrice-将inputAmount数量的BNB存入WBNB这个合约,并返回交易收据
                  const txReceipt = await callWithGasPrice(wethContract, 'deposit', undefined, {
                    value: `0x${inputAmount.raw.toString(16)}`,
                  })
                  //toSignificant用于将JSBI类型的整数值转换为一个指定精度的字符串表示
                  addTransaction(txReceipt, { summary: `Wrap ${inputAmount.toSignificant(6)} BNB to WBNB` })
                } catch (error) {
                  console.error('Could not deposit', error)
                }
              }
            : undefined,
        inputError: sufficientBalance ? undefined : 'Insufficient BNB balance',
      }
    }
    //如果输入货币是WBNB，且输出货币是BNB
    if (currencyEquals(WETH[chainId], inputCurrency) && outputCurrency === ETHER) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  //提现交易
                  const txReceipt = await callWithGasPrice(wethContract, 'withdraw', [
                    `0x${inputAmount.raw.toString(16)}`,
                  ])
                  addTransaction(txReceipt, { summary: `Unwrap ${inputAmount.toSignificant(6)} WBNB to BNB` })
                } catch (error) {
                  console.error('Could not withdraw', error)
                }
              }
            : undefined,
        inputError: sufficientBalance ? undefined : 'Insufficient WBNB balance',
      }
    }
    return NOT_APPLICABLE
  }, [wethContract, chainId, inputCurrency, outputCurrency, inputAmount, balance, addTransaction, callWithGasPrice])
}
