import { ChainId, JSBI, Percent, Token, WETH } from '@pancakeswap/sdk'
import { BUSD, DAI, USDT, BTCB, CAKE, WBNB, UST, ETH, USDC, TESTUSDT, TESTTOKEN, TESTWBNB } from './tokens'

// 正式
export const ROUTER_ADDRESS = '0x10ED43C718714eb63d5aA57B78B54704E256024E'
export const SWAP_ADDRESS = '0x341fB14a9D11771B81edDA96Cf63Bc89Fcf96ADF'

// //测试
// export const ROUTER_ADDRESS = '0xd99d1c33f9fc3444f8101754abc46c52416550d1'

// // pancake 测试 对应node-modules里@pancakeswap/sdk/dist/sdk.esm.js，因为那里一yarn或npm就会被覆盖，所以这里留个备份
// var FACTORY_ADDRESS = '0x6725f303b657a9451d8ba641348b6761a6cc7a17';
// var INIT_CODE_HASH = '0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66';




export const ORDER_GRAPH = {
  1: 'https://api.thegraph.com/subgraphs/name/pine-finance/pine_orders',
  4: 'https://api.thegraph.com/subgraphs/name/pine-finance/pine_orders_rinkeby',
  // 56:'https://api.thegraph.com/subgraphs/name/miaoluyao/elephantswaps',
  56:'https://api.thegraph.com/subgraphs/name/miaoluyao/newlimitorders',
  97:'https://api.thegraph.com/subgraphs/name/miaoluyao/limitorders'
}

// 给Limit限价用的
export const UNISWAPEX_ADDRESSES = {
  56: '0x74C58472f67A698DD12D61f82c3Cb0474fE9851e',
  97: '0x79b128b35A00b822Fa4c2467402B1f6CDA305b8f'
}

//LimitOrders   https://etherscan.io/address/0x037fc8e71445910e1E0bBb2a0896d5e9A7485318#code
export const LIMIT_ORDER_MODULE_ADDRESSES = {
  56:'0x3c03d6c925e78B8f0cD403E79b97B57DBaCF641e',
  97:'0x7789c980ac1D97D45385e4207EC8bA2CeF9d43F5'
}

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET], CAKE[ChainId.MAINNET], BUSD[ChainId.MAINNET], USDT, BTCB, UST, ETH, USDC],
  [ChainId.TESTNET]: [TESTWBNB]//WETH[ChainId.TESTNET], CAKE[ChainId.TESTNET], BUSD[ChainId.TESTNET] ,TESTUSDT,TESTTOKEN
}

/**
 * Addittional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
  [ChainId.TESTNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.MAINNET]: [BUSD[ChainId.MAINNET], CAKE[ChainId.MAINNET], USDT],
  [ChainId.TESTNET]: [WETH[ChainId.TESTNET], CAKE[ChainId.TESTNET], BUSD[ChainId.TESTNET]],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET], DAI, BUSD[ChainId.MAINNET], USDT],
  [ChainId.TESTNET]: [WETH[ChainId.TESTNET], CAKE[ChainId.TESTNET], BUSD[ChainId.TESTNET]],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [CAKE[ChainId.MAINNET], WBNB],
    [BUSD[ChainId.MAINNET], USDT],
    [DAI, USDT],
  ],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 BNB
// THRESHOLD 临界值
// 在 Pancake 中，Percent 对象用于表示百分比。它是 PancakeSwap 的一个自定义对象，用于处理百分比计算和操作。
// new Percent(JSBI.BigInt(50), JSBI.BigInt(10000)) 的意义是创建一个 Percent 对象，它表示 50/10000，即 0.5%。
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C',
]

export { default as farmsConfig } from './farms'
export { default as poolsConfig } from './pools'
export { default as ifosConfig } from './ifo'
