import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getMulticallAddress} from 'utils/addressHelpers'
import {EggState} from '../types'
import {fetchEggDao, fetchEggNft, fetchEggToken, fetchUsdt} from './FetchEgg'


// Async thunks
export const fetchEggDataAsync = createAsyncThunk<EggState, { account: string }>(
    'egg/fetchDataAsync',
    async ({account = getMulticallAddress()}) => {
        const tokenData = await fetchEggToken(account)
        const nftData = await fetchEggNft(account)
        const daoData = await fetchEggDao(account)
        // console.log("daoData xxxx:", daoData)
        // const nftData = {}
        // const daoData = {}
        return {
            ...tokenData,
            ...nftData,
            ...daoData
        }
    },
)

// Async thunks
export const fetchUsdtAllowanceAsync = createAsyncThunk<EggState, { account: string }>(
    'egg/fetchUsdtAllowanceAsync',
    async ({account = getMulticallAddress()}) => {
        const usdt = await fetchUsdt(account)
        return usdt
    },
)

const initialState: EggState = {
    sysInfo: {
        price: "0",
        totalSupply: "0",
        circulatingSupply: "0",
        lastRebasedTime: "0",
        pairBalance: "0",
        balanceOfTreasuryReceiver: "0",
        totalDaoAmount: "0",
        balanceOfSafuuInsuranceFundReceiver: "0",
        fireBalance: "0",
        startTradingTime: "0",
        startIdoTime: undefined,
        idoInterval: "0",
        idoTotal: "0",
        idoMx: "0"
    },
    token: {
        teamIdoAmount: 0,
        teamIdoNum: 0,
        balance: "0",
        isShare: false,
        referTimes: [],
        users: [],
        buyAmounts: [],
        referIdoNum: 0,
        shareRefund: true,
        idoAmount: "0",
        parent: undefined,
    },
    nft: {
        totalSupply: "0",
        balance: "0"
    },
    dao: {
        receivedDividends: "0",
        availableDividends: "0",
        startTrade: false,
    },
    usdt: {
        allowance: undefined,
        balance: "0"
    }
}

export const eggSlice = createSlice({
    name: 'Egg',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchEggDataAsync.fulfilled, (state, action) => {
            state.sysInfo = action.payload.sysInfo;
            state.token = action.payload.token;
            state.nft = action.payload.nft;
            state.dao = action.payload.dao;
        })
        // Update oracle address
        builder.addCase(fetchUsdtAllowanceAsync.fulfilled, (state, action) => {
            state.usdt = action.payload.usdt
        })
    },
})

export default eggSlice.reducer
