import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme { }
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: inherit;
  }
  body {
    font-family: 'Kanit',sans-serif;
    background: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  @font-face {
    font-family: montserrat semibold;
      src: local("Montserrat SemiBold"), url(${`${process.env.PUBLIC_URL}/media/Montserrat-SemiBold.fa8441f3.ttf`}) format("truetype")
  }

  @font-face {
      font-family: montserrat;
      src: local("Montserrat"), url(${`${process.env.PUBLIC_URL}/media/Montserrat-Regular.3cd78665.ttf`}) format("truetype")
  }

  @font-face {
      font-family: montserrat bold;
      src: local("Montserrat Bold"), url(${`${process.env.PUBLIC_URL}/media/Montserrat-Bold.079ca05d.ttf`}) format("truetype")
  }

  @font-face {
      font-family: montserrat medium;
      src: local("Montserrat Medium"), url(${`${process.env.PUBLIC_URL}/media/Montserrat-Medium.e2d60bc4.ttf`}) format("truetype")
  }

  @font-face {
      font-family: montserrat light;
      src: local("Montserrat Light"), url(${`${process.env.PUBLIC_URL}/media/Montserrat-Light.02afb26f.ttf`}) format("truetype")
  }

  @font-face {
      font-family: courier;
      src: local("Courier"), url(${`${process.env.PUBLIC_URL}/media/CourierPrime-Regular.68d89efc.ttf`}) format("truetype")
  }
    

`

export default GlobalStyle
