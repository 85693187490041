import BigNumber from 'bignumber.js'
import {
    getCakeAddress,
    getEggPoolAddress,
    getEggShareAddress,
    getNftAddress,
    getUsdtAddress
} from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import eggAbi from 'config/abi/cake.json'
import nftAbi from 'config/abi/nft.json'
import eggShareAbi from 'config/abi/eggShare.json'
import eggPoolAbi from 'config/abi/eggPool.json'
import {EggState} from 'state/types'
import {ERC20_ABI} from 'config/abi/erc20'
import {ethers} from 'ethers'

export const fetchEggToken = async (account: string): Promise<EggState> => {
    const address = getCakeAddress()
    const calls = [
        {
            address,
            name: 'getSysInfo'
        },
        {
            address,
            name: 'balanceOf',
            params: [account]
        },
        {
            address,
            name: 'userInfo',
            params: [account]
        },
        {
            address,
            name: 'getReferList',
            params: [account]
        }
    ]
    const [[[price, totalSupply, circulatingSupply, pairBalance, fireBalance, lastRebasedTime,
        shareMx, shareHolderLength, idoMx, idoTotal, startIdoTime, idoInterval, startTradingTime,
        totalDaoAmount, totalInviteAmount,
        balanceOfTreasuryReceiver, balanceOfSafuuInsuranceFundReceiver, balanceOfFreeDaoAddress]],
        [balance],
        [referTime, parent, isShare, referIdoNum, sRefund, idoAmount, idoRefund, buyAmount],
        [_referTimes, users, _buyAmounts]
    ] = await multicall(eggAbi, calls)

    const shareRefund = await fetchEggShareRefund(account)

    const referTimes = !_referTimes ? [] : _referTimes.map(v => new BigNumber(v._hex).toNumber())
    const buyAmounts = !_buyAmounts ? [] : _buyAmounts.map(v => new BigNumber(v._hex).div(10 ** 8).toNumber())

    let teamIdoAmount = 0;
    let teamIdoNum = 0;
    if (account) {
        const response = await fetch(`https://agent.eggplus.org/get_egg_promote/${account}`)
        const res = await response.json()
        if (res.code === 0) {
            teamIdoAmount = res.data.idoamount
            teamIdoNum = res.data.idonum
        }
        console.log("res:", res)
    }

    return {
        sysInfo: {
            price: new BigNumber(price._hex).div(10 ** 18).toString(),
            totalSupply: new BigNumber(totalSupply._hex).div(10 ** 8).toString(),
            circulatingSupply: new BigNumber(circulatingSupply._hex).div(10 ** 8).toString(),
            lastRebasedTime: new BigNumber(lastRebasedTime._hex).toString(),
            pairBalance: new BigNumber(pairBalance._hex).div(10 ** 8).toString(),
            balanceOfTreasuryReceiver: new BigNumber(balanceOfTreasuryReceiver._hex).div(10 ** 8).toString(),
            totalDaoAmount: new BigNumber(totalDaoAmount._hex).div(10 ** 8).toString(),
            balanceOfSafuuInsuranceFundReceiver: new BigNumber(balanceOfSafuuInsuranceFundReceiver._hex).div(10 ** 8).toString(),
            fireBalance: new BigNumber(fireBalance._hex).div(10 ** 8).toString(),
            startTradingTime: new BigNumber(startTradingTime._hex).toString(),
            startIdoTime: new BigNumber(startIdoTime._hex).toString(),
            idoInterval: new BigNumber(idoInterval._hex).toString(),
            idoTotal: new BigNumber(idoTotal._hex).div(10 ** 18).toString(),
            idoMx: new BigNumber(idoMx._hex).div(10 ** 18).toString(),
        },
        token: {
            teamIdoAmount,
            teamIdoNum,
            balance: new BigNumber(balance._hex).div(10 ** 8).toString(),
            isShare,
            referTimes,
            users,
            buyAmounts,
            referIdoNum: new BigNumber(referIdoNum._hex).toNumber(),
            shareRefund,
            idoAmount: new BigNumber(idoAmount._hex).div(10 ** 18).toString(),
            parent,
        }
    }
}

export const fetchEggShareRefund = async (account: string): Promise<boolean> => {
    const address = getEggShareAddress()
    const calls = [
        {
            address,
            name: 'userInfo',
            params: [account]
        }
    ]
    const [[shareRefund]] = await multicall(eggShareAbi, calls)
    return shareRefund
}

export const fetchEggNft = async (account: string): Promise<EggState> => {
    const address = getNftAddress()
    const calls = [
        {
            address,
            name: 'totalSupply'
        },
        {
            address,
            name: 'balanceOf',
            params: [account]
        }
    ]
    const [[totalSupply], [balance]] = await multicall(nftAbi, calls)

    return {
        nft: {
            totalSupply: new BigNumber(totalSupply._hex).toString(),
            balance: new BigNumber(balance._hex).toString(),
        }
    }
}

export const fetchEggDao = async (account: string): Promise<EggState> => {
    const address = getEggPoolAddress()
    const calls = [
        {
            address,
            name: 'userStakeInfo',
            params: [account]
        },
    ]
    const [[availableDividends, receivedDividends, startTrade]] = await multicall(eggPoolAbi, calls)

    return {
        dao: {
            receivedDividends: new BigNumber(receivedDividends._hex).div(10 ** 8).toString(),
            availableDividends: new BigNumber(availableDividends._hex).div(10 ** 8).toString(),
            startTrade,
        }
    }
}

export const fetchUsdt = async (account: string): Promise<EggState> => {
    const address = getUsdtAddress()
    const egg = getCakeAddress()
    const calls = [
        {
            address,
            name: 'allowance',
            params: [account, egg]
        },
        {
            address,
            name: 'balanceOf',
            params: [account]
        }
    ]
    const [[amount], [balance]] = await multicall(ERC20_ABI, calls)

    return {
        usdt: {
            allowance: ethers.constants.MaxUint256.eq(new BigNumber(amount._hex).toString()),
            balance: new BigNumber(balance._hex).div(10 ** 18).toString(),
        }
    }
}
