import BigNumber from 'bignumber.js'
import {getMiner5xAddress, getMiner5xTimerAddress, getUsdtAddress} from 'utils/addressHelpers'
import multicall from 'utils/multicall'
// eslint-disable-next-line import/named
import {Miner5xState} from 'state/types'
import {ERC20_ABI} from 'config/abi/erc20'
import Miner5x from 'config/abi/miner5x.json'
import MinerTimer5x from 'config/abi/minerTimer5x.json'

export const fetch5xSysInfo = async (): Promise<Miner5xState> => {
    const address = getMiner5xAddress()
    let calls = [
        {
            address,
            name: 'getSysInfo',
            params: []
        }
    ]
    const [[lastUser, [fomoReward, lastTime, minerBalance, totalBack, countUsers, deals, volume, countFree]]] = await multicall(Miner5x, calls)

    const timerAddress = getMiner5xTimerAddress()
    calls = [
        {
            address: timerAddress,
            name: 'interval',
            params: []
        }
    ]
    const [[interval]] = await multicall(MinerTimer5x, calls)

    return {
        sysInfo: {
            lastUser,
            fomoReward: new BigNumber(fomoReward._hex).div(10 ** 18).toString(10),
            interval:new BigNumber(interval._hex).toString(10),
            lastTime: new BigNumber(lastTime._hex).toString(10),
            minerBalance: new BigNumber(minerBalance._hex).div(10 ** 18).toString(10),
            totalBack: new BigNumber(totalBack._hex).div(10 ** 18).toString(10),
            countUsers: new BigNumber(countUsers._hex).toString(10),
            deals: new BigNumber(deals._hex).toString(10),
            volume: new BigNumber(volume._hex).div(10 ** 18).toString(10),
            countFree: new BigNumber(countFree._hex).toString(10)
        }
    }
}

export const fetch5xUserInfo = async (account: string): Promise<Miner5xState> => {
    const address = getMiner5xAddress()
    const calls = [
        {
            address,
            name: 'getUserInfo',
            params: [account]
        }
    ]
    const [[parent, oneGetFree, [hasReward, referReward, invest, lastHatch, usersMiner, pendingReward, pendingToken]]] = await multicall(Miner5x, calls)

    return {
        userInfo: {
            parent,
            oneGetFree,
            hasReward: new BigNumber(hasReward._hex).div(10 ** 18).toString(10),
            referReward: new BigNumber(referReward._hex).div(10 ** 18).toString(10),
            invest: new BigNumber(invest._hex).div(10 ** 18).toString(10),
            lastHatch: new BigNumber(lastHatch._hex).toString(10),
            usersMiner: new BigNumber(usersMiner._hex).toString(10),
            pendingReward: new BigNumber(pendingReward._hex).div(10 ** 18).toString(10),
            pendingToken: new BigNumber(pendingToken._hex).toString(10),
        }
    }
}

export const fetch5xUsdt = async (account: string): Promise<Miner5xState> => {
    const address = getUsdtAddress()
    const egg = getMiner5xAddress()
    const calls = [
        {
            address,
            name: 'allowance',
            params: [account, egg]
        },
        {
            address,
            name: 'balanceOf',
            params: [account]
        }
    ]
    const [[amount], [balance]] = await multicall(ERC20_ABI, calls)

    return {
        usdt: {
            allowance: new BigNumber(amount._hex).toNumber() > 10,
            balance: new BigNumber(balance._hex).div(10 ** 18).toString(),
        }
    }
}
