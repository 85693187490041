import React from 'react'
import styled from 'styled-components'
import { Card } from '@pancakeswap/uikit'
import useTheme from 'hooks/useTheme'

export const BodyWrapper = styled(Card)`
  border-radius: 24px;
  max-width: 436px;
  width: 100%;
  z-index: 1;
  overflow: visible;
  box-shadow: 0px 6px 16px 0px rgb(22 39 117 / 9%);
  padding:0;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  const { theme } = useTheme()
  return <BodyWrapper background={theme.colors.gradients.card} >{children}</BodyWrapper>
}
