import { Trade, Percent, currencyEquals } from '@pancakeswap/sdk'
import { ZERO_PERCENT, ONE_HUNDRED_PERCENT } from '../config/constants/index'

// returns whether tradeB is better than tradeA by at least a threshold percentage amount
export function isTradeBetter(
  tradeA: Trade | undefined | null,
  tradeB: Trade | undefined | null,
  minimumDelta: Percent = ZERO_PERCENT,
): boolean | undefined {
  // 如果B不存在，那么B肯定不是更好的交易，返回false
  if (tradeA && !tradeB) return false
  // 如果B存在，A不存在，那么B肯定是更好的那一个，返回true
  if (tradeB && !tradeA) return true
  // 如果都不存在，那么就是undefined
  if (!tradeA || !tradeB) return undefined

  //如果A的tradeType 和 B 的tradeType不相同
  // 或 A和 B的输入货币不相同（address和chainid都一致视为相同）
  // 或 A和 B的输出货币不相同（address和chainid都一致视为相同）
  // 总结就是两个要是相同的交易，不是就抛出一个错误 “交易不可比较”
  if (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !currencyEquals(tradeB.outputAmount.currency, tradeB.outputAmount.currency)
  ) {
    throw new Error('Trades are not comparable')
  }

  //如果百分比是0%，直接比较executionPrice
  /*
  在 PancakeSwap 中，Trade 对象的 executionPrice 属性代表交易的执行价格。

executionPrice 表示在进行交易时实际成交的价格。它是以固定数量的输入货币（输入金额）换取的输出货币（输出金额）的比率。

具体来说，executionPrice 是一个 Price 对象，用于表示输入货币与输出货币的兑换比率。Price 对象是 PancakeSwap 中的一个自定义对象，用于处理货币之间的价格和兑换率。

通过 executionPrice，可以了解到在实际交易中，以多少输出货币来换取单位数量的输入货币。

在交易过程中，executionPrice 是根据交易的输入数量和输出数量计算得出的。它可以用于衡量交易的成本、价格滑点和交易效率等指标。

需要注意的是，executionPrice 是一个动态的值，它可能会因为市场条件、流动性和其他因素而发生变化。因此，在进行交易分析和决策时，需要结合其他因素综合考虑。

总结而言，PancakeSwap 中的 Trade 对象的 executionPrice 属性表示交易的实际执行价格，即以多少输出货币来换取单位数量的输入货币。
  */

  /*
  兑换率表示输入货币与输出货币之间的比率，用于衡量以多少输入货币可以兑换多少输出货币。

如果兑换率的值较大，意味着以较小的输入货币数量可以兑换更多的输出货币。这通常被视为有利的情况，因为您可以获得更多的输出货币，相对于您的输入货币数量。

举个例子，假设兑换率为 10，表示以 1 个单位的输入货币可以兑换 10 个单位的输出货币。如果您有 1 单位的输入货币，您可以通过交易获得 10 单位的输出货币。
   */
  if (minimumDelta.equalTo(ZERO_PERCENT)) {
    //这里如果A的兑换率小于B的兑换率，那B就是更好的交易
    return tradeA.executionPrice.lessThan(tradeB.executionPrice)
  }
  /*
  当比较两个 Trade 对象的 executionPrice 时，有时会将前一个 executionPrice 乘以一个比例，比如 *(1+5%)，这是为了考虑价格滑点（Price Impact）的影响。

价格滑点是指在进行大额交易或流动性较低的交易时，市场价格可能会发生变动，导致实际成交价格与预期价格之间存在差距。这种差距可能会对交易的成本和效益产生影响。

通过将前一个 executionPrice 乘以比例 *(1+5%)，可以模拟考虑价格滑点后的 executionPrice，以更准确地比较两个交易的执行价格。

举个例子，假设前一个 executionPrice 是 10，而 *(1+5%) 表示将前一个 executionPrice 增加 5%。那么，新的比较值将为 10 * (1+5%) = 10.5。这样，考虑了价格滑点后的 executionPrice 就可以更准确地进行比较。

这种操作可以帮助交易者更好地评估交易的效果，并考虑市场价格的波动性。通过模拟价格滑点，可以更全面地考虑交易的成本、风险和预期收益，从而做出更明智的决策。
   */
  // 这里模拟了5%的滑点，如果算上滑点影响，B的兑换率仍然更大，那么B是更好的交易
  return tradeA.executionPrice.raw.multiply(minimumDelta.add(ONE_HUNDRED_PERCENT)).lessThan(tradeB.executionPrice)
}

export default isTradeBetter
