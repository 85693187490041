import { Interface, FunctionFragment } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useBlock } from 'state/block/hooks'
import { AppDispatch, AppState } from '../index'
import {
  addMulticallListeners,
  Call,
  removeMulticallListeners,
  parseCallKey,
  toCallKey,
  ListenerOptions,
} from './actions'

export interface Result extends ReadonlyArray<any> {
  readonly [key: string]: any
}

type MethodArg = string | number | BigNumber
type MethodArgs = Array<MethodArg | MethodArg[]>

type OptionalMethodInputs = Array<MethodArg | MethodArg[] | undefined> | undefined

function isMethodArg(x: unknown): x is MethodArg {
  return ['string', 'number'].indexOf(typeof x) !== -1
}

function isValidMethodArgs(x: unknown): x is MethodArgs | undefined {
  return (
    x === undefined ||
    (Array.isArray(x) && x.every((xi) => isMethodArg(xi) || (Array.isArray(xi) && xi.every(isMethodArg))))
  )
}

interface CallResult {
  readonly valid: boolean
  readonly data: string | undefined
  readonly blockNumber: number | undefined
}

const INVALID_RESULT: CallResult = { valid: false, blockNumber: undefined, data: undefined }

// use this options object
export const NEVER_RELOAD: ListenerOptions = {
  blocksPerFetch: Infinity,
}

// the lowest level call for subscribing to contract data
/*
这段代码是一个自定义的 React Hook，用于执行多个调用（calls）并返回调用结果数组（CallResult[]）。

该 Hook 接受以下参数：

calls：一个包含多个调用对象的数组，每个调用对象包含合约地址和调用数据。
options：可选的监听选项。
首先，代码使用 useActiveWeb3React Hook 获取当前活动的 Web3React 上下文中的链 ID（chainId）。

然后，代码使用 useSelector Hook 从 Redux store 中获取 multicall 的调用结果（callResults）。

接着，代码使用 useDispatch Hook 获取 Redux store 的 dispatch 函数。

接下来，代码使用 useMemo Hook 根据 calls 生成一个序列化的调用键数组（serializedCallKeys）。这个键数组是根据每个调用对象的合约地址和调用数据生成的，并进行排序和序列化。

然后，代码使用 useEffect Hook 监听链 ID 和序列化的调用键数组的变化。当链 ID 或调用键数组发生变化时，会执行回调函数。在回调函数中，根据链 ID 和调用键数组，调用 Redux store 的 addMulticallListeners 和 removeMulticallListeners 方法来添加或移除监听器。

最后，代码使用 useMemo Hook 将调用结果进行转换，返回一个包含多个调用结果的数组。对于每个调用结果，会判断链 ID 和调用对象是否存在，然后从调用结果中提取数据和区块号，并返回一个 CallResult 对象。

总体来说，这段代码用于执行多个调用，并返回调用结果数组。它还实现了监听器的添加和移除逻辑，以确保在链 ID 或调用键数组发生变化时进行相应的操作。这个 Hook 可以方便地在 React 组件中使用，以获取多个调用的结果，并根据需要进行处理。
 */
function useCallsData(calls: (Call | undefined)[], options?: ListenerOptions): CallResult[] {
  const { chainId } = useActiveWeb3React()
  const callResults = useSelector<AppState, AppState['multicall']['callResults']>(
    (state) => state.multicall.callResults,
  )
  const dispatch = useDispatch<AppDispatch>()

  const serializedCallKeys: string = useMemo(
    () =>
      JSON.stringify(
        calls
          ?.filter((c): c is Call => Boolean(c))
          ?.map(toCallKey)
          ?.sort() ?? [],
      ),
    [calls],
  )

  // update listeners when there is an actual change that persists for at least 100ms
  useEffect(() => {
    const callKeys: string[] = JSON.parse(serializedCallKeys)
    if (!chainId || callKeys.length === 0) return undefined
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const calls = callKeys.map((key) => parseCallKey(key))

    // for (var i = 0;i<callKeys.length;i++){
    //   if (callKeys[i].toLowerCase().indexOf('0xda9bba394344be05a0646e8fac307c1ea1d98b6c') !== -1){
    //     debugger
    //   }
    // }
    dispatch(
      addMulticallListeners({
        chainId,
        calls,
        options,
      }),
    )

    return () => {
      dispatch(
        removeMulticallListeners({
          chainId,
          calls,
          options,
        }),
      )
    }
  }, [chainId, dispatch, options, serializedCallKeys])//

  return useMemo(
    () =>
      calls.map<CallResult>((call) => {
        if (!chainId || !call) return INVALID_RESULT

        const result = callResults[chainId]?.[toCallKey(call)]
        let data
        if (result?.data && result?.data !== '0x') {
          // eslint-disable-next-line prefer-destructuring
          data = result.data
        }

        return { valid: true, data, blockNumber: result?.blockNumber }
      }),
    [callResults, calls, chainId],
  )
}

interface CallState {
  readonly valid: boolean
  // the result, or undefined if loading or errored/no data
  readonly result: Result | undefined
  // true if the result has never been fetched
  readonly loading: boolean
  // true if the result is not for the latest block
  readonly syncing: boolean
  // true if the call was made and is synced, but the return data is invalid
  readonly error: boolean
}

const INVALID_CALL_STATE: CallState = { valid: false, result: undefined, loading: false, syncing: false, error: false }
const LOADING_CALL_STATE: CallState = { valid: true, result: undefined, loading: true, syncing: true, error: false }

/*
这段代码是一个用于将调用结果转换为 `CallState` 对象的函数。

该函数接受以下参数：
- `callResult`：调用结果对象，包含调用的有效性、数据、区块号等信息。
- `contractInterface`：合约接口对象。
- `fragment`：函数片段对象。
- `latestBlockNumber`：最新区块号。

首先，代码检查 `callResult` 是否存在，如果不存在则返回 `INVALID_CALL_STATE`。

然后，从 `callResult` 中提取 `valid`、`data` 和 `blockNumber`。

接下来，根据不同的情况进行判断和处理：
- 如果调用结果无效（`valid` 为 false），则返回 `INVALID_CALL_STATE`。
- 如果调用结果有效但没有区块号（`blockNumber` 为 undefined），则返回 `LOADING_CALL_STATE`。
- 如果合约接口、函数片段或最新区块号不存在，则返回 `LOADING_CALL_STATE`。

如果以上条件都不满足，代码会继续执行。首先，根据调用结果中的数据判断调用是否成功（`success`）。

然后，判断调用是否正在同步中，即当前区块号是否小于最新区块号（`syncing`）。

接着，定义一个变量 `result`，用于存储解码后的结果对象。

如果调用成功且数据存在，则尝试使用合约接口的 `decodeFunctionResult` 方法将数据解码为结果对象。如果解码过程中出现错误，则打印调试信息，并返回一个具有错误标志的 `CallState` 对象。

最后，根据不同的情况返回一个包含验证结果、加载状态、同步状态、结果对象和错误标志的 `CallState` 对象。

总体来说，这段代码用于将调用结果、合约接口、函数片段和最新区块号转换为一个 `CallState` 对象。根据调用结果的不同情况，确定验证结果、加载状态、同步状态、结果对象和错误标志，并返回相应的 `CallState` 对象。
 */
function toCallState(
  callResult: CallResult | undefined,
  contractInterface: Interface | undefined,
  fragment: FunctionFragment | undefined,
  latestBlockNumber: number | undefined,
): CallState {
  if (!callResult) return INVALID_CALL_STATE
  const { valid, data, blockNumber } = callResult
  if (!valid) return INVALID_CALL_STATE
  if (valid && !blockNumber) return LOADING_CALL_STATE
  if (!contractInterface || !fragment || !latestBlockNumber) return LOADING_CALL_STATE
  const success = data && data.length > 2
  const syncing = (blockNumber ?? 0) < latestBlockNumber
  let result: Result | undefined
  if (success && data) {
    try {
      result = contractInterface.decodeFunctionResult(fragment, data)
    } catch (error) {
      console.debug('Result data parsing failed', fragment, data)
      return {
        valid: true,
        loading: false,
        error: true,
        syncing,
        result,
      }
    }
  }
  return {
    valid: true,
    loading: false,
    syncing,
    result,
    error: !success,
  }
}

export function useSingleContractMultipleData(
  contract: Contract | null | undefined,
  methodName: string,
  callInputs: OptionalMethodInputs[],
  options?: ListenerOptions,
): CallState[] {
  const fragment = useMemo(() => contract?.interface?.getFunction(methodName), [contract, methodName])

  const calls = useMemo(
    () =>
      contract && fragment && callInputs && callInputs.length > 0
        ? callInputs.map<Call>((inputs) => {
            return {
              address: contract.address,
              callData: contract.interface.encodeFunctionData(fragment, inputs),
            }
          })
        : [],
    [callInputs, contract, fragment],
  )

  const results = useCallsData(calls, options)

  const { currentBlock } = useBlock()

  return useMemo(() => {
    return results.map((result) => toCallState(result, contract?.interface, fragment, currentBlock))
  }, [fragment, contract, results, currentBlock])
}

export function useMultipleContractSingleData(
  addresses: (string | undefined)[],
  contractInterface: Interface,
  methodName: string,
  callInputs?: OptionalMethodInputs,
  options?: ListenerOptions,
): CallState[] {
  // debugger
  const fragment = useMemo(() => contractInterface.getFunction(methodName), [contractInterface, methodName])
  const callData: string | undefined = useMemo(
    () =>
      fragment && isValidMethodArgs(callInputs)
        ? contractInterface.encodeFunctionData(fragment, callInputs)
        : undefined,
    [callInputs, contractInterface, fragment],
  )

  const calls = useMemo(
    () =>
      fragment && addresses && addresses.length > 0 && callData
        ? addresses.map<Call | undefined>((address) => {
            return address && callData
              ? {
                  address,
                  callData,
                }
              : undefined
          })
        : [],
    [addresses, callData, fragment],
  )

  const results = useCallsData(calls, options)

  const { currentBlock } = useBlock()

  return useMemo(() => {
    return results.map((result) => toCallState(result, contractInterface, fragment, currentBlock))
  }, [fragment, results, contractInterface, currentBlock])
}

/*
该 Hook 接受以下参数：

contract：合约对象，可以是 Contract 类型、null 或 undefined。
methodName：合约方法名。
inputs：可选的方法输入参数。
options：可选的监听选项。
首先，代码使用 useMemo Hook 来缓存根据 contract 和 methodName 获取的方法片段（fragment）。这是通过调用合约对象的接口的 getFunction 方法来实现的。

接下来，代码使用另一个 useMemo Hook 来缓存根据 contract、fragment 和 inputs 创建的调用数组（calls）。这个调用数组包含一个对象，其中包括合约地址和调用数据，调用数据是使用合约接口的 encodeFunctionData 方法将方法名和输入参数编码而成。

然后，代码使用 useCallsData Hook 来执行调用，并获取调用结果数组（result）。

接着，代码使用 useBlock Hook 来获取当前区块的信息，其中包括当前区块号（currentBlock）。

最后，代码使用 useMemo Hook 将调用结果、合约接口、方法片段和当前区块号转换为一个 CallState 对象，并返回。

总体来说，这段代码用于执行单个合约方法的调用，并将调用结果、合约接口、方法片段和当前区块号转换为一个 CallState 对象。这个 Hook 可以方便地在 React 组件中使用，以获取合约方法的调用结果，并根据需要进行处理。
 */
export function useSingleCallResult(
  contract: Contract | null | undefined,
  methodName: string,
  inputs?: OptionalMethodInputs,
  options?: ListenerOptions,
): CallState {
  const fragment = useMemo(() => contract?.interface?.getFunction(methodName), [contract, methodName])

  const calls = useMemo<Call[]>(() => {
    return contract && fragment && isValidMethodArgs(inputs)
      ? [
          {
            address: contract.address,
            callData: contract.interface.encodeFunctionData(fragment, inputs),
          },
        ]
      : []
  }, [contract, fragment, inputs])

  const result = useCallsData(calls, options)[0]
  const { currentBlock } = useBlock()

  return useMemo(() => {
    return toCallState(result, contract?.interface, fragment, currentBlock)
  }, [result, contract, fragment, currentBlock])
}
