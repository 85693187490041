import React from 'react'
import { Box, BoxProps } from '@pancakeswap/uikit'

const Container: React.FC<BoxProps> = ({ children, mx = "auto",maxWidth="1200px", ...props }) => (
  <Box px='10px' mx={mx} maxWidth={maxWidth}  {...props}>
    {children}
  </Box>
)

export default Container
