import { MenuEntry } from '@pancakeswap/uikit'
import tokens from 'config/constants/tokens'
import { ContextApi } from 'contexts/Localization/types'
import { getAddress } from 'utils/addressHelpers'


export const socials = [
  // {
  //   label: "Telegram",
  //   icon: "TelegramIcon",
  //   href: "https://t.me/Egg_Plus",
  // },
  // {
  //   label: "Twitter",
  //   icon: "TwitterIcon",
  //   href: "https://twitter.com/EggPlusOfficial",
  // },
];


const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'home',
    href: '/',
  },
  {
    label: 'OC AI',
    icon: 'gpt',
    href:  `https://ai.unitedgaming.vip/`,
    // href: '/ocai',
  },
  {
    label: t('Limit Swap'),
    icon: 'audit',
    href: '/limit',
  },
  {
    label: t('Exchange'),
    icon: 'trade',
    href: `/swap`,
    // href: `/swap?outputCurrency=${tokens.cake.address[56]}`,
  },
  {
    label: t('Liquidity'),
    icon: 'ido',
    href: '/liquidity',
  },
  // {
  //   label: t('V2'),
  //   icon: 'ido',
  //   href: '/swapv2',
  // },
  {
    label: t('ELE2'),
    icon: 'ido',
    href: 'https://swapv2.mostadvideo.com/',
  },
  {
    label: t('铭刻市场'),
    icon: 'ido',
    href: 'https://elephantordinals.com/',
  },
  {
    label: t('Analyzer'),
    icon: 'charts',
    href: `https://www.geckoterminal.com/zh/bsc/pools/0x4ea2121ef2d4fdac3d3481f5c487e29cdd9baaf9`,
  },
  {
    label: t('Farm'),
    icon: 'farm',
    href: `https://oceannft.io/farms`,
  },
  // {
  //   label: t('Farm'),
  //   icon: 'farm',
  //   href: '/farms',
  // },
  {
    label: `NFT ${t('Farming')}`,
    icon: 'markets',
    href: '/medal',
  },
  {
    label: t('Whitepaper'),
    icon: 'book',
    href:  `https://elephantswaps.com/whitepaper.pdf`,
  },
  // {
  //   label: 'Telegram',
  //   icon: 'telegram',
  //   href:  `https://t.me/OceanBNO`,
  // },
  // {
  //   label: t('LP分红'),
  //   icon: 'rewards',
  //   href:  `https://oceannft.io/stake`,
  // },
  {
    label: 'Twitter',
    icon: 'twitter',
    href:  `https://twitter.com/OceanBNO`,
  },

]

export default config

