import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch ,RouteComponentProps} from 'react-router-dom'
import { ResetCSS } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { useFetchProfile } from 'state/profile/hooks'
import { DatePickerPortal } from 'components/DatePicker'

// import { getFullDisplayBalance } from 'utils/formatBalance'
// import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'
// import { getCakeAddress } from 'utils/addressHelpers'

import GlobalStyle from './style/Global'
import Menu from './components/Menu'

import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import EasterEgg from './components/EasterEgg'
import history from './routerHistory'
// Views included in the main bundle
import Swap from './views/Swap'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './views/AddLiquidity/redirects'
import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects'
import { RedirectPathToSwapOnly, RedirectToSwap } from './views/Swap/redirects'

import { RedirectPathToLimitOnly, RedirectToLimit } from './views/MyLimit/redirects'


// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page

const NotFound = lazy(() => import('./views/NotFound'))
// const LimitSwap = lazy(() => import('./views/LimitOrder/LimitSwap'))
const LimitSwap = lazy(() => import('./views/MyLimit'))
// const LimitSwap = lazy(() => import('./views/LimitSwap'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const Liquidity = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const Describe = lazy(() => import('./views/Describe'))
const InnerWeb = lazy(() => import('./views/InnerWeb'))
const Dashboard = lazy(() => import('./views/Dashboard'))
const Rewards = lazy(() => import('./views/Rewards'))
const Medal = lazy(() => import('./views/Medal'))
const Ido = lazy(() => import('./views/Ido'))
const Account = lazy(() => import('./views/Account'))
const Calculator = lazy(() => import('./views/Calculator'))
const Miner = lazy(() => import('./views/Miner'))

const Farms = lazy(() => import('./views/Farms'))

const SwapV2 = lazy(() => import('./views/V2'))




// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  useFetchProfile()
  usePollCoreFarmData()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />

      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>

          <Switch>
            <Route path="/farms">
              <Farms />
            </Route>
          <Route exact strict path="/" component={Describe} />
            <Route exact strict path="/ocai" render={() => <InnerWeb weburl={'https://ai.unitedgaming.vip/'}/>} />

            {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
            <Route exact strict path="/limit" component={LimitSwap} />
            <Route exact strict path="/limit/:outputCurrency" component={RedirectToLimit} />
            <Route exact strict path="/swap" component={Swap} />
            <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
            <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
            <Route exact strict path="/find" component={PoolFinder} />
            <Route exact strict path="/liquidity" component={Liquidity} />
            <Route exact strict path="/create" component={RedirectToAddLiquidity} />
            <Route exact path="/add" component={AddLiquidity} />
            <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact path="/create" component={AddLiquidity} />
            <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
            <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
            <Route exact strict path="/medal" component={Medal} />
            {/*<Route exact strict path="/swapv2" component={SwapV2} />*/}
            {/* Redirect */}
            <Route path="/pool">
              <Redirect to="/liquidity" />
            </Route>
            <Route path="/staking">
              <Redirect to="/pools" />
            </Route>
            <Route path="/syrup">
              <Redirect to="/pools" />
            </Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>

        </SuspenseWithChunkError>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
