import { useCallback } from 'react'
import { BigNumber } from "@ethersproject/bignumber"
import ethers, { Contract, CallOverrides } from 'ethers'
import { useGasPrice } from 'state/user/hooks'
import { get } from 'lodash'
import { DEFAULT_GAS_LIMIT } from 'config'
import { caculateGasLimit } from 'utils'

/**
 * Perform a contract call with a gas price returned from useGasPrice
 * @param contract Used to perform the call
 * @param methodName The name of the method called
 * @param methodArgs An array of arguments to pass to the method
 * @param overrides An overrides object to pass to the method. gasPrice passed in here will take priority over the price returned by useGasPrice
 * @returns https://docs.ethers.io/v5/api/providers/types/#providers-TransactionReceipt
 */
export function useCallWithGasPrice() {
  const gasPrice = useGasPrice()//获取燃气费

  //返回一个交易
  const callWithGasPrice = useCallback(
    async (
      contract: Contract,
      methodName: string,
      methodArgs: any[] = [],
      overrides: CallOverrides = null,
      scale = 1.5
    ): Promise<ethers.providers.TransactionResponse> => {
      // 获取合约方法
      const contractMethod = get(contract, methodName)
      const hasManualGasPriceOverride = overrides?.gasPrice

      //计算燃气限制
      const gasLimit = await caculateGasLimit(contract, methodName, [...methodArgs, hasManualGasPriceOverride ? { ...overrides } : { ...overrides, gasPrice }], scale)
      const tx = await contractMethod(
        ...methodArgs,
        hasManualGasPriceOverride ? { ...overrides, gasLimit } : { ...overrides, gasPrice, gasLimit },
      )

      return tx
    },
    [gasPrice],
  )

  return { callWithGasPrice }
}
