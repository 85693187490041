import React from 'react'
import styled from 'styled-components'

const Imgtyled = styled.img`
    position: absolute;
    top: -25px;
    left: -62%;
    z-index: -1;
    display: none;

    
  ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
  }


`


const SwapBg = () => {
    return null
}

export default SwapBg