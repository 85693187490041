import React from 'react'
import { Flex, IconButton, CogIcon, useModal } from '@pancakeswap/uikit'
import SettingsModal from './SettingsModal'

// const AllocationMatch: React.FC<{ color: string; text: string }> = ({ color, text }) => {

const GlobalSettings: React.FC<{color?: string}> = ({ color = "navText"}) => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)

  return (
    <>
       <Flex alignItems="center" style={{ flex: 1 }}><br /></Flex>
    <Flex>
      <IconButton onClick={onPresentSettingsModal} variant="text" scale="sm" mr="8px">
        <CogIcon height={22} width={22} color={color} />
      </IconButton>
    </Flex>
    </>
  )
}

export default GlobalSettings
