/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit'
import {
  addLimtTransaction,
  addTransaction,
  checkedTransaction,
  clearAllTransactions,
  finalizeTransaction, NonBscFarmTransactionType,
  SerializableTransactionReceipt, TransactionType,
} from './actions'
import { Order } from '../../views/LimitOrder/components/LimitOrderTable/limit-orders-lib'
import { confirmOrderCancellation, confirmOrderSubmission, saveOrder } from '../../utils/localStorageOrders'
// import { saveOrder } from '../../views/MyLimit/MyLocalStorageOrder'

const now = () => new Date().getTime()

export interface TransactionDetails {
  hash: string
  approval?: { tokenAddress: string; spender: string }
  summary?: string
  claim?: { recipient: string }
  receipt?: SerializableTransactionReceipt
  lastCheckedBlockNumber?: number
  addedTime: number
  confirmedTime?: number
  from: string,
  translatableSummary?: { text: string; data?: Record<string, string | number> },
  type?: TransactionType,
  order?: Order,
  nonBscFarm?: NonBscFarmTransactionType
}

export interface TransactionState {
  [chainId: number]: {
    [txHash: string]: TransactionDetails
  }
}

export const initialState: TransactionState = {}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addTransaction, (transactions, { payload: { chainId, from, hash, approval, summary, claim } }) => {
      //判断交易是否已存在
      if (transactions[chainId]?.[hash]) {
        throw Error('Attempted to add existing transaction.')
      }
      const txs = transactions[chainId] ?? {}
      //将交易信息存下来
      txs[hash] = { hash, approval, summary, claim, from, addedTime: now() }
      transactions[chainId] = txs
    })
    .addCase(clearAllTransactions, (transactions, { payload: { chainId } }) => {
      if (!transactions[chainId]) return
      transactions[chainId] = {}
    })
    .addCase(checkedTransaction, (transactions, { payload: { chainId, hash, blockNumber } }) => {
      const tx = transactions[chainId]?.[hash]
      if (!tx) {
        return
      }
      if (!tx.lastCheckedBlockNumber) {
        tx.lastCheckedBlockNumber = blockNumber
      } else {
        tx.lastCheckedBlockNumber = Math.max(blockNumber, tx.lastCheckedBlockNumber)
      }
    }).
addCase(
  addLimtTransaction,
  (
    transactions,
    { payload: { chainId, from, hash, approval, summary, translatableSummary, claim, type, order, nonBscFarm } },
  ) => {
    if (transactions[chainId]?.[hash]) {
      throw Error('Attempted to add existing transaction.')
    }
    const txs = transactions[chainId] ?? {}
    txs[hash] = {
      hash,
      approval,
      summary,
      translatableSummary,
      claim,
      from,
      addedTime: now(),
      type,
      order,
      nonBscFarm,
    }
    transactions[chainId] = txs
    if (order) saveOrder(chainId, from, order, true)
    // if (order) saveOrder(from, order, chainId)
  },
)
    .addCase(finalizeTransaction, (transactions, { payload: { hash, chainId, receipt } }) => {
      const tx = transactions[chainId]?.[hash]
      if (!tx) {
        return
      }
      tx.receipt = receipt
      tx.confirmedTime = now()
      if (tx.type === 'limit-order-submission') {
        confirmOrderSubmission(chainId, receipt.from, hash, receipt.status !== 0)
      } else if (tx.type === 'limit-order-cancellation') {
        confirmOrderCancellation(chainId, receipt.from, hash, receipt.status !== 0)
      }
      // else if (tx.type === 'non-bsc-farm') {
      //   if (tx.nonBscFarm.steps[0].status === FarmTransactionStatus.PENDING) {
      //     if (receipt.status === FarmTransactionStatus.FAIL) {
      //       tx.nonBscFarm = { ...tx.nonBscFarm, status: receipt.status }
      //     }
      //
      //     tx.nonBscFarm.steps[0] = {
      //       ...tx.nonBscFarm.steps[0],
      //       status: receipt.status,
      //     }
      //   } else {
      //     tx.nonBscFarm = nonBscFarm
      //   }
    }),
)
