import {Contract} from '@ethersproject/contracts'
import {getAddress} from '@ethersproject/address'
import {AddressZero} from '@ethersproject/constants'
import {JsonRpcSigner, Web3Provider} from '@ethersproject/providers'
import {BigNumber} from '@ethersproject/bignumber'
import {abi as IUniswapV2Router02ABI} from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import {ChainId, Currency, CurrencyAmount, ETHER, JSBI, Percent, Token} from '@pancakeswap/sdk'
import {ROUTER_ADDRESS, SWAP_ADDRESS} from '../config/constants'
import {BASE_BSC_SCAN_URLS, DEFAULT_GAS_LIMIT} from '../config'
import {TokenAddressMap} from '../state/lists/hooks'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
    try {
        /*getAddress
        这段代码主要是对给定的地址进行验证和处理，并返回一个结果。

首先，代码检查传入的地址是否为字符串类型，如果不是则抛出一个错误。

然后，代码使用正则表达式对地址进行匹配。如果地址匹配到以 "0x" 开头的 40 个十六进制字符，那么它被认为是一个有效的以太坊地址。

如果地址没有以 "0x" 开头，代码会在地址前添加 "0x"。

然后，代码会对地址进行校验和检查。如果地址中包含大小写混合的字符，并且校验和与地址不匹配，则抛出一个错误。

如果地址不是以太坊地址，但匹配到以 "XE" 开头的 32 或 33 位字符，那么它被认为是一个 ICAP 地址。代码会对 ICAP 地址进行校验和检查，如果校验和与地址不匹配，则抛出一个错误。
ICAP 地址是一种用于在不同链之间传输数字资产的地址格式。它是一种国际标准，用于在不同的金融机构之间传输资金。ICAP 地址通常以 "XE" 开头，后面跟着两位国家代码、两位校验位和 30 到 31 位数字和字母组合
最后，如果地址既不是以太坊地址也不是 ICAP 地址，则抛出一个错误。

在每个条件分支中，代码会根据不同的情况对地址进行处理，并将处理后的结果赋值给变量 result。

整体来说，这段代码用于验证和处理给定的地址，并返回一个经过处理的结果。如果地址不满足要求，则会抛出相应的错误。
         */
        return getAddress(value)
    } catch {
        return false
    }
}

export function getBscScanLink(
    data: string | number,
    type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
    chainId: ChainId = ChainId.MAINNET,
): string {
    switch (type) {
        case 'transaction': {
            return `${BASE_BSC_SCAN_URLS[chainId]}/tx/${data}`
        }
        case 'token': {
            return `${BASE_BSC_SCAN_URLS[chainId]}/token/${data}`
        }
        case 'block': {
            return `${BASE_BSC_SCAN_URLS[chainId]}/block/${data}`
        }
        case 'countdown': {
            return `${BASE_BSC_SCAN_URLS[chainId]}/block/countdown/${data}`
        }
        default: {
            return `${BASE_BSC_SCAN_URLS[chainId]}/address/${data}`
        }
    }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
    const parsed = isAddress(address)
    if (!parsed) {
        throw Error(`Invalid 'address' parameter '${address}'.`)
    }
    return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber, scale = 1.5): BigNumber {
    if (scale === 1.5)
        return value.mul(3).div(2).gt(DEFAULT_GAS_LIMIT) ? value.mul(3).div(2) : BigNumber.from(DEFAULT_GAS_LIMIT)
    return value.mul(1150).div(1000)
}

export async function caculateGasLimit(contract: Contract, methodName: string, methodArgs: any[] = [], scale = 1.5): Promise<BigNumber> {
    if (!contract[methodName]) {
        throw new Error(`Method ${methodName} doesn't exist on ${contract.address}`)
    }
    const rawGasEstimation = await contract.estimateGas[methodName](...methodArgs)
    if (scale === 1.5)
        return rawGasEstimation.mul(3).div(2).gt(DEFAULT_GAS_LIMIT) ? rawGasEstimation.mul(3).div(2) : BigNumber.from(DEFAULT_GAS_LIMIT)
    if (scale === 1)
        return rawGasEstimation
    return rawGasEstimation.mul(1150).div(1000)
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
    return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
    if (slippage < 0 || slippage > 10000) {
        throw Error(`Unexpected slippage value: ${slippage}`)
    }
    return [
        JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
        JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
    ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
    return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
    return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
    if (!isAddress(address) || address === AddressZero) {
        throw Error(`Invalid 'address' parameter '${address}'.`)
    }
    //创建合约，合约地址，ABI，provider或signer（看是否有account）
    return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
    return getContract(ROUTER_ADDRESS, IUniswapV2Router02ABI, library, account)
}

export function getSwapRouterContract(_: number, library: Web3Provider, account?: string): Contract {
    return getContract(SWAP_ADDRESS, IUniswapV2Router02ABI, library, account)
}

export function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
    if (currency === ETHER) return true
    return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

// export function getBlockExploreLink(
//   data: string | number,
//   type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
//   chainIdOverride?: number,
// ): string {
//     const chainId = chainIdOverride || ChainId.MAINNET
//     const chain = chains.find((c) => c.id === chainId)
//     if (!chain) return bsc.blockExplorers.default.url
//     switch (type) {
//         case 'transaction': {
//             return `${chain.blockExplorers.default.url}/tx/${data}`
//         }
//         case 'token': {
//             return `${chain.blockExplorers.default.url}/token/${data}`
//         }
//         case 'block': {
//             return `${chain.blockExplorers.default.url}/block/${data}`
//         }
//         case 'countdown': {
//             return `${chain.blockExplorers.default.url}/block/countdown/${data}`
//         }
//         default: {
//             return `${chain.blockExplorers.default.url}/address/${data}`
//         }
//     }
// }
