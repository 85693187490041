
import tokens, { VERSION } from 'config/constants/tokens'

const getTokenLogoURL = (address: string) =>
  [
    `/images/tokens/${address}.png?ver=${VERSION}`,
    `/images/tokens/${address}.svg?ver=${VERSION}`,
    `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${address}/logo.png?ver=${VERSION}`,
    `/images/tokens/${address.toLocaleLowerCase()}.svg?ver=${VERSION}`
  ]



export default getTokenLogoURL
