import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'BNO-USDT LP',
    lpAddresses: {
      97: '',
      56: '0xB87D4038E51F653A4E0E0d8C2302534e439bBDbE',
    },
    token: tokens.cake,
    quoteToken: tokens.usdt,
  },
  {
    pid: 1,
    lpSymbol: 'BULL-USDT LP',
    lpAddresses: {
      97: '',
      56: '0xa7e5ff37FcF36b94D14Bf844abE3b2985d556e93',
    },
    token: tokens.bull,
    quoteToken: tokens.usdt,
  },
  {
    pid: 2,
    lpSymbol: 'COW-BNO LP',
    lpAddresses: {
      97: '',
      56: '0x4B9c234779A3332b74DBaFf57559EC5b4cB078BD',
    },
    token: tokens.cow,
    quoteToken: tokens.cake,
  },
]

export default farms
