import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Elephant Swap',
  description:
    'The best AMM+NFT DEX for newborn projects on Binance Smart Chain (BSC)',
  image: 'images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {

  return {
    title: `${t('Elephant Swap')}`,
  }

}
