import { CurrencyAmount, ETHER, JSBI } from '@pancakeswap/sdk'
import { MIN_BNB } from '../config/constants'

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 *
 * 接下来，如果currencyAmount的货币类型是以太坊（ETH），则会进行一些逻辑判断。如果currencyAmount的金额大于MIN_BNB（一个预定义的最小值），则返回一个新的以太坊金额对象，金额为原始金额减去MIN_BNB。否则，返回一个金额为零的以太坊金额对象。

 最后，如果currencyAmount的货币类型不是以太坊（ETH），则直接返回currencyAmount，表示没有进行任何处理。
 */
export function maxAmountSpend(currencyAmount?: CurrencyAmount): CurrencyAmount | undefined {
  if (!currencyAmount) return undefined
  if (currencyAmount.currency === ETHER) {
    if (JSBI.greaterThan(currencyAmount.raw, MIN_BNB)) {
      // 用来确保用户不会发送太多的BNB，所以他们最终的结果是< 0.01
      return CurrencyAmount.ether(JSBI.subtract(currencyAmount.raw, MIN_BNB))
    }
    return CurrencyAmount.ether(JSBI.BigInt(0))
  }
  return currencyAmount
}

export default maxAmountSpend
