/* eslint-disable no-param-reassign */
import { isTradeBetter } from 'utils/trades'
import { Currency, CurrencyAmount, Pair, Token, Trade } from '@pancakeswap/sdk'
import flatMap from 'lodash/flatMap'
import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

import { useUserSingleHopOnly } from 'state/user/hooks'
import {
  BASES_TO_CHECK_TRADES_AGAINST,
  CUSTOM_BASES,
  BETTER_TRADE_LESS_HOPS_THRESHOLD,
  ADDITIONAL_BASES,
} from '../config/constants'
import { PairState, usePairs } from './usePairs'
import { wrappedCurrency } from '../utils/wrappedCurrency'

import { useUnsupportedTokens } from './Tokens'

function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency): Pair[] {

  const { chainId } = useActiveWeb3React()

  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined]

  const bases: Token[] = useMemo(() => {
    if (!chainId) return []

    const common = BASES_TO_CHECK_TRADES_AGAINST[chainId] ?? [] //一个Token[] 有常见的WBNB,BNO(本项目的主要代币)，BUSD，USDT, BTCB, UST, ETH, USDC等
    const additionalA = tokenA ? ADDITIONAL_BASES[chainId]?.[tokenA.address] ?? [] : []//ADDITIONAL_BASES为空，目前没有值为[]
    const additionalB = tokenB ? ADDITIONAL_BASES[chainId]?.[tokenB.address] ?? [] : []//ADDITIONAL_BASES为空，目前没有值为[]

    return [...common, ...additionalA, ...additionalB]
  }, [chainId, tokenA, tokenB])

  //返回一个类似 [
  //   [A, A], [A, B], [A, C],
  //   [B, A], [B, B], [B, C],
  //   [C, A], [C, B], [C, C]
  // ] 的结果，将bases里的元素两两配对了
  const basePairs: [Token, Token][] = useMemo(
    () => flatMap(bases, (base): [Token, Token][] => bases.map((otherBase) => [base, otherBase])),
    [bases],
  )

  const allPairCombinations: [Token, Token][] = useMemo(
    () => {
      if (tokenA && tokenB){
        // debugger
        let temparr = [
          // the direct pair
          [tokenA, tokenB],//TokenA和TokenB配对
          // token A against all bases
          ...bases.map((base): [Token, Token] => [tokenA, base]),// TokenA和所有base里的元素分别配对
          // token B against all bases
          ...bases.map((base): [Token, Token] => [tokenB, base]),// TokenB和所有base里的元素分别配对
          // each base against all bases
          ...basePairs,//上一步配对好的basePairs
        ]
        let temparr1 = temparr.filter((tokens): tokens is [Token, Token] => Boolean(tokens[0] && tokens[1]))// 配对里有空值的去掉
        let temparr2 = temparr1.filter(([t0, t1]) => t0.address !== t1.address) // 配对两个Token相等的去掉
        let temparr3 = temparr2.filter(([tokenA_, tokenB_]) => {
          // 如果customBasesA和customBasesB都不存在，表示tokenA_和tokenB_的地址都不在CUSTOM_BASES中，那么返回true，允许这对配对通过过滤条件。
          //
          // 如果customBasesA存在，但tokenB_不在customBasesA中，表示tokenB_不属于tokenA_的自定义基础资产，那么返回false，过滤掉这对配对。
          //
          // 如果customBasesB存在，但tokenA_不在customBasesB中，表示tokenA_不属于tokenB_的自定义基础资产，那么返回false，过滤掉这对配对。
          //
          // 如果以上条件都不满足，表示tokenA_和tokenB_都属于各自的自定义基础资产，或者customBases中的自定义基础资产列表为空，那么返回true，允许这对配对通过过滤条件。
          // debugger
          if (!chainId) return true
          const customBases = CUSTOM_BASES[chainId]

          const customBasesA: Token[] | undefined = customBases?.[tokenA_.address]
          const customBasesB: Token[] | undefined = customBases?.[tokenB_.address]

          if (!customBasesA && !customBasesB) return true

          if (customBasesA && !customBasesA.find((base) => tokenB_.equals(base))) return false
          if (customBasesB && !customBasesB.find((base) => tokenA_.equals(base))) return false

          return true
        })
        return temparr3

      }else{
        return []
      }
    },
    [tokenA, tokenB, bases, basePairs, chainId],
  )

  // 返回一个[PairState, Pair | null][]
  // 数组的每一项是[PairState, Pair | null]，PairState代表Pair合约获取储备量状态，Pair代表交易对，里面是new Pair(new TokenAmount(token0,token0的储备量), new TokenAmount(token1, token1的储备量)),
  // 而所谓储备量则是由流动性相关的逻辑添加的
  const allPairs = usePairs(allPairCombinations)

  // only pass along valid pairs, non-duplicated pairs
  return useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, Pair] => Boolean(result[0] === PairState.EXISTS && result[1]))
          // filter out duplicated pairs
          //使用reduce函数的特定写法，用于将一个数组转换为一个对象。
        // <{ [pairAddress: string]: Pair }>定义了最终的返回值类型
          //   ((memo, [, curr]) => {具体的函数方法},{})memo为叠加值，{}为初始值，代表是一个空对象，[, curr]通过解构赋值得到具体的每次遍历的Pair对象，赋给curr变量，这里的数组本为[PairState, Pair]，第二位才是我们想要的值，所以前面用,占位
  // {
  //   memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
  //   return memo
  // } 代表将Pair里的liquidityToken.address作为key，将Pair存到key为liquidityToken.address的对象中，且不重复存取，类似Java的Map
          .reduce<{ [pairAddress: string]: Pair }>((memo, [, curr]) => {
            memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
            return memo
          }, {}),
      ),
    //Object.values 又将所有的属性值弄成一个数组，比如{a:1,b:2,c:3} ，最终得到的就是[1,2,3] ,所以这里其实就是返回了一个按liquidityToken.address去重，且去掉了PairState，只要Pair的Pair数组
    [allPairs],
  )
}

//最大中转次数
const MAX_HOPS = 3

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTradeExactIn(currencyAmountIn?: CurrencyAmount, currencyOut?: Currency): Trade | null {
  //返回了一个Pair数组
  const allowedPairs = useAllCommonPairs(currencyAmountIn?.currency, currencyOut)
  //是否直接转换两个代币，不经过多次跳跃，类似买火车票时的直达和中转的概念
  const [singleHopOnly] = useUserSingleHopOnly()

  return useMemo(() => {
    // debugger
    if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
      if (singleHopOnly) {
        return (
          //直达，直接返回一个最合适的Trade
          Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, { maxHops: 1, maxNumResults: 1 })[0] ??
          null
        )
      }
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade | null = null
      //遍历最大中转次数
      for (let i = 1; i <= MAX_HOPS; i++) {
        //获取一个合适的Trade
        const currentTrade: Trade | null =
          Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, { maxHops: i, maxNumResults: 1 })[0] ??
          null
        // if current trade is best yet, save it 判断currentTrade是不是比bestTradeSoFar更好，是的话把值赋给bestTradeSoFar
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }

    return null
  }, [allowedPairs, currencyAmountIn, currencyOut, singleHopOnly])
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(currencyIn?: Currency, currencyAmountOut?: CurrencyAmount): Trade | null {
  const allowedPairs = useAllCommonPairs(currencyIn, currencyAmountOut?.currency)

  const [singleHopOnly] = useUserSingleHopOnly()

  return useMemo(() => {
    // debugger
    if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
      if (singleHopOnly) {
        return (
          Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, { maxHops: 1, maxNumResults: 1 })[0] ??
          null
        )
      }
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade | null = null
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade =
          Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, { maxHops: i, maxNumResults: 1 })[0] ??
          null
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }
    return null
  }, [currencyIn, currencyAmountOut, allowedPairs, singleHopOnly])
}

export function useIsTransactionUnsupported(currencyIn?: Currency, currencyOut?: Currency): boolean {
  const unsupportedTokens: { [address: string]: Token } = useUnsupportedTokens()
  const { chainId } = useActiveWeb3React()

  const tokenIn = wrappedCurrency(currencyIn, chainId)
  const tokenOut = wrappedCurrency(currencyOut, chainId)

  // if unsupported list loaded & either token on list, mark as unsupported
  if (unsupportedTokens) {
    if (tokenIn && Object.keys(unsupportedTokens).includes(tokenIn.address)) {
      return true
    }
    if (tokenOut && Object.keys(unsupportedTokens).includes(tokenOut.address)) {
      return true
    }
  }

  return false
}
