import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getMulticallAddress} from 'utils/addressHelpers'
// eslint-disable-next-line import/named
import {Miner5xState} from '../types'
import {fetch5xSysInfo, fetch5xUsdt, fetch5xUserInfo} from './FetchMiner5x'


export const fetch5xDataAsync = createAsyncThunk<Miner5xState, { account: string }>(
    'egg/fetch5xDataAsync',
    async ({account = getMulticallAddress()}) => {
        console.log("account====:", account)
        if (account) {
            // const usdt = await fetch5xUsdt(account)
            const sysInfo = await fetch5xSysInfo()
            const userInfo = await fetch5xUserInfo(account)
            console.log("daoData xxxx:")
            return {
                ...sysInfo,
                ...userInfo,
                // ...usdt,
            }
        }
        const sysInfo = await fetch5xSysInfo()
        return {
            ...sysInfo,
        }

    },
)

// Async thunks
export const fetch5xUsdtAllowanceAsync = createAsyncThunk<Miner5xState, { account: string }>(
    'egg/fetch5xUsdtAllowanceAsync',
    async ({account = getMulticallAddress()}) => {
        const usdt = await fetch5xUsdt(account)
        return {
            ...usdt,
        }
    },
)

const initialState: Miner5xState = {
    sysInfo: {
        lastUser: '',
        fomoReward: '0',
        interval: '30',
        lastTime: '0',
        minerBalance: '0',
        totalBack: '0',
        countUsers: '0',
        deals: '0',
        volume: '0',
        countFree: '0',
    },
    userInfo: {
        parent: "",
        oneGetFree: true,
        hasReward: '0',
        referReward: '0',
        invest: '0',
        lastHatch: '0',
        usersMiner: '0',
        pendingReward: '0',
        pendingToken: '0',
    },
    usdt: {
        allowance: undefined,
        balance: "0"
    }
}

export const miner5xSlice = createSlice({
    name: 'Miner5x',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetch5xDataAsync.fulfilled, (state, action) => {
            state.sysInfo = action.payload.sysInfo;
            state.userInfo = action.payload.userInfo;
        })
        // Update oracle address
        builder.addCase(fetch5xUsdtAllowanceAsync.fulfilled, (state, action) => {
            state.usdt = action.payload.usdt
        })
    },
})

export default miner5xSlice.reducer
