import { namehash } from 'ethers/lib/utils'
import { useMemo } from 'react'
import { useSingleCallResult } from '../../state/multicall/hooks'
import { isAddress } from '../../utils'
import isZero from '../../utils/isZero'
import { useENSRegistrarContract, useENSResolverContract } from '../useContract'
import useDebounce from '../useDebounce'

/**
 * Does a reverse lookup for an address to find its ENS name.
 * Note this is not the same as looking up an ENS name to find an address.
 */
export default function useENSName(address?: string): { ENSName: string | null; loading: boolean } {
  // Debounce 是一种常用的前端开发技术，用于限制函数的执行频率。
  /*
  在没有防抖的情况下，debouncedAddress 的值会直接等于 address 的值。这意味着每当 address 发生变化时，debouncedAddress 会立即跟随变化。

然而，如果应用了防抖逻辑，即使用 useDebounce 这个自定义 Hook 对 address 进行防抖处理，那么 debouncedAddress 的值会有一定的延迟。

具体来说，在 address 发生变化后，会等待一段时间（根据防抖逻辑中设置的延迟时间）才会将最新的 address 值赋给 debouncedAddress。这样可以确保在 address 连续变化的情况下，只有在一定时间内保持不变时，才会更新 debouncedAddress 的值。

因此，如果应用了防抖逻辑，debouncedAddress 的值可能会有一定的延迟，并不会立即和 address 的值相同。它会在 address 在一定时间内保持不变后才会更新为最新的值。
   */
  const debouncedAddress = useDebounce(address, 200)
  const ensNodeArgument = useMemo(() => {
    if (!debouncedAddress || !isAddress(debouncedAddress)) return [undefined]
    try {
      //使用 namehash 函数将 debouncedAddress 转换为一个 ENS 节点。具体来说，它会将 debouncedAddress 转换为以 .addr.reverse 结尾的 ENS 节点格式，并进行小写处理。然后经过namehash转换
      return debouncedAddress ? [namehash(`${debouncedAddress.toLowerCase().substr(2)}.addr.reverse`)] : [undefined]
    } catch (error) {
      return [undefined]
    }
  }, [debouncedAddress])
  //获取一个注册ens的合约，合约地址为0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e
  const registrarContract = useENSRegistrarContract(false)
  // ENS合约的resolver方法是用于获取指定域名的解析器（resolver）合约地址。该方法接受一个参数，即域名的hash值，返回对应域名的解析器合约地址。
  const resolverAddress = useSingleCallResult(registrarContract, 'resolver', ensNodeArgument)
  const resolverAddressResult = resolverAddress.result?.[0]

  const resolverContract = useENSResolverContract(
    resolverAddressResult && !isZero(resolverAddressResult) ? resolverAddressResult : undefined,
    false,
  )
  // Ens Resolver合约的name方法是用于获取指定地址的解析器合约的名称。该方法接受一个参数，即解析器合约的地址，返回对应解析器合约的名称。
  const name = useSingleCallResult(resolverContract, 'name', ensNodeArgument)

  const changed = debouncedAddress !== address
  return {
    ENSName: changed ? null : name.result?.[0] ?? null,
    loading: changed || resolverAddress.loading || name.loading,
  }
}
